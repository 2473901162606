<template>
 <vx-card>
   <div class="vx-row">
    <div class="vx-col md:w-full mt-4">
      <span style="font-weight: bold;">NB:</span> <span style="font-style: italic;"> Les champs suivis du signe (*) sont obligatoires pour l'enregistrement du client.</span>
    </div>
      <div class="vx-col md:w-1/3 w-full mt-4">
        <vs-select autocomplete v-model="Civilite" class="w-full select-large mt-4" label="Civilité *">
          <vs-select-item :key="index" :value="item.text" :text="item.value" v-for="(item,index) in civilites" class="w-full" />
        </vs-select>
      </div>
      <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-input label="Nom *" :value="Nom" @change.native="Nom=$event.target.value" class="w-full mt-4" />
      </div>
      <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-input label="Prenoms *" :value="Prenoms" @change.native="Prenoms=$event.target.value" class="w-full mt-4" />
      </div>
      <div class="vx-col md:w-1/3 w-full mt-4">
        <div class="mt-4">
          <label class="text-sm">Date de naissance</label>
          <datepicker :language="languages[language]" format="dd MMMM yyyy" v-model="DateNaiss" class="w-full" ></datepicker>
        </div>
      </div>
      <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-input label="Alias" :value="Alias" @change.native="Alias=$event.target.value" class="w-full mt-4" />
      </div>

      <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-input label="Profession" :value="Profession" @change.native="Profession=$event.target.value" class="w-full mt-4" />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-input label="Mobile *" :value="mobile" @change.native="mobile=$event.target.value" class="w-full" />
      </div>
      <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-input type="email" label="Email" :value="email" @change.native="email=$event.target.value" class="w-full" />
      </div>
      <!-- <div class="vx-col md:w-1/3 w-full mt-4">
        <vs-input label="Adresse" :value="adresse" @change.native="adresse=$event.target.value" class="w-full" />
      </div> -->
      <div class="vx-col md:w-1/3 w-full mt-4">
        <vs-select autocomplete label="Pays *" class="w-full" v-model="pays">
          <vs-select-item :key="index" :value="item.pays" :text="item.pays" v-for="(item, index) in paysData" />
        </vs-select>
      </div>
      <div class="vx-col md:w-1/3 w-full mt-4">
        <vs-select autocomplete label="Ville *" class="w-full" v-model="ville">
          <vs-select-item v-for="(item, index) in villeData" :key="index" :value="item.libelleVille" :text="item.libelleVille"/>
        </vs-select>
      </div>
      <div class="vx-col md:w-1/3 w-full mt-4">
        <vs-select autocomplete label="Quartier *" class="w-full" v-model="quartier">
          <vs-select-item v-for="(item, index) in quartierData" :key="index" :value="item.libelleQtier" :text="item.libelleQtier"/>
        </vs-select>
      </div>
      <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-input label="Localisation" :value="localite" @change.native="localite=$event.target.value" class="w-full" />
      </div>
      <!-- <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-input label="Fixe" :value="tel" @change.native="tel=$event.target.value" class="w-full" />
      </div> -->

      <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-input label="Activité extra" :value="Activitextra" @change.native="Activitextra=$event.target.value" class="w-full" />
      </div>
      <div class="vx-col md:w-1/3 w-full mt-4">
        <vs-select autocomplete label="Langue *" class="w-full" v-model="Langue">
          <vs-select-item :key="index" :value="item.code" :text="item.langue" v-for="(item, index) in Languages" />
        </vs-select>
      </div>
      <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-input label="Remarque" :value="remarque" @change.native="remarque=$event.target.value" class="w-full" />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col md:w-1/3 w-full mt-4">
        <vs-checkbox label="CarteFidelite" v-model="CarteFidelite" class="w-full" > Carte de Fidelite </vs-checkbox>
      </div>
      <div class="vx-col md:w-1/3 w-full mt-4">
        <vs-checkbox label="Assure" v-model="Assure" class="w-full" > Assuré </vs-checkbox>
      </div>
      <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-checkbox label="DCD" v-model="DCD" class="w-full" > DCD </vs-checkbox>
      </div>
      <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-checkbox label="PasSMS" v-model="PasSMS" class="w-full" > Pas SMS </vs-checkbox>
      </div>
      <div class="vx-col md:w-1/3 w-full mt-4">
          <vs-checkbox label="PasMail" v-model="PasMail" class="w-full" > Pas Mail </vs-checkbox>
      </div>

    </div>
    <div class="vx-row mt-5">
      <div class="vx-col w-full text-right">
        <vs-button class="mr-3 mb-2" @click="formSubmitted">ENREGISTRER</vs-button>
        <vs-button color="danger" class="mb-2" @click.native="Civilite = Nom = Prenoms = Alias = Profession = DateNaiss = adresse = email = pays = ville = quartier = localite = tel = mobile = fax = cp = Activitextra = remarque = CarteFidelite = Assure = DCD = ChefFamille = PasSMS = PasMail = null" >ANNULER</vs-button>
      </div>
    </div>
 </vx-card>

</template>

<script>
const flatPickr = () => import('vue-flatpickr-component')
import 'flatpickr/dist/flatpickr.css'
const Datepicker = () => import('vuejs-datepicker')
import * as lang from 'vuejs-datepicker/src/locale'


export default {
  components: {
    flatPickr,
    Datepicker
  },
  data () {
    return {
      activeUserInfos: null,
      IdOfficine: null,
      IdUser: null,
      Civilite: null,
      Nom: null,
      Prenoms: null,
      Alias: null,
      Profession: null,
      DateNaiss: null,
      Activitextra: null,
      Langue: null,
      adresse: null,
      email: null,
      pays: null,
      ville: null,
      quartier: null,
      localite: null,
      tel: null,
      mobile: null,
      fax: null,
      cp: null,
      remarque: null,
      CarteFidelite: false,
      Assure: false,
      DCD: false,
      photo: null,
      ChefFamille: false,
      PasSMS: false,
      PasMail: false,
      ordonnances: [],
      commandes: [],
      language: 'fr',
      languages: lang,
      civilites: [
        {
          text: 'Prof',
          value: 'Prof'
        },
        {
          text: 'Dr',
          value: 'Dr'
        },
        {
          text: 'M',
          value: 'M'
        },
        {
          text: 'Mme',
          value: 'Mme'
        },
        {
          text: 'Mlle',
          value: 'Mlle'
        }
      ]

    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.Civilite !== null && this.Nom !== null && this.Prenoms !== null && this.DateNaiss !== null && this.mobile !== null && this.pays !== null &&  this.ville !== null && this.quartier !== null && this.Langue !== null
    },
    villeData () {
      return this.$store.state.pays.villes
    },
    paysData () {
      return this.$store.state.pays.pays
    },
    quartierData () {
      return this.$store.state.pays.quartiers
    },
    Languages () {
      return this.$store.state.Languages
    }

  },
  methods: {

    formSubmitted () {

      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.IdUser,
        Civilite: this.Civilite,
        Nom: this.Nom,
        Prenoms: this.Prenoms,
        Alias: this.Alias,
        Profession: this.Profession,
        DateNaiss: this.DateNaiss,
        Activitextra: this.Activitextra,
        Langue: this.Langue,
        adresse: this.adresse,
        email: this.email,
        pays: this.pays,
        ville: this.ville,
        quartier: this.quartier,
        localite: this.localite,
        tel: this.tel,
        mobile: this.mobile,
        /* fax: this.fax,
        cp: this.cp, */
        remarque: this.remarque,
        CarteFidelite: this.CarteFidelite,
        Assure: this.Assure,
        DCD: this.DCD,
        /* ChefFamille: this.ChefFamille, */
        PasSMS: this.PasSMS,
        PasMail: this.PasMail,
        id: this.$route.params.clientId

      }
      this.$vs.loading({
        type: 'sound'
      })

      /* setTimeout(() => {
        this.$vs.loading.close()
        this.$router.push('/apps/client/client-list')
      }, 1500) */


      this.$store.dispatch('client/updateClient', payload)
        .then(() => {
          this.$vs.notify({
            title: 'Client Modifié !',
            text: 'success !!',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.reset_data()
          this.$router.push('/apps/client/client-list')
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `error: ${err}`,
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'danger'
          })
          this.$vs.loading.close()
          this.$router.push('/apps/client/client-list')
        })
    },
    reset_data () {
      this.Civilite =
      this.Nom =
      this.Prenoms =
      this.Alias =
      this.Profession =
      this.DateNaiss =
      this.Activitextra =
      this.Langue =
      this.adresse =
      this.remarque = ''
      this.CarteFidelite =
      this.Assure =
      this.DCD =
      /* this.ChefFamille = */
      this.PasSMS =
      this.PasMail = false

    },
    successUpload (event) {
      this.$vs.notify({color:'success', title:'Upload Success', text:'Lorem ipsum dolor sit amet, consectetur'})
      const filePaths = event.currentTarget.response
      console.log(filePaths)
    },
    showPhotoData () {
      console.log('Photos >', this.photo)
    },
    getClientById () {
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('client/getClientById', this.$route.params.clientId)
        .then((resp) => {
          const client = resp.data
          this.IdOfficine = client.IdOfficine
          this.IdUser = client.IdUser
          this.Civilite = client.Civilite
          this.Nom = client.Nom
          this.Prenoms = client.Prenoms
          this.Alias = client.Alias
          this.Profession = client.Profession
          this.DateNaiss = client.DateNaiss
          this.Activitextra = client.Activitextra
          this.Langue = client.Langue
          this.adresse = client.Adresse.adresse
          this.email = client.Adresse.email
          this.pays = client.Adresse.pays
          this.ville = client.Adresse.ville
          this.quartier = client.Adresse.quartier
          this.localite = client.Adresse.localite
          this.tel = client.Adresse.tel
          this.mobile = client.Adresse.mobile
          /* this.fax = client.Adresse.fax
          this.cp = client.Adresse.cp */
          this.remarque = client.remarque
          this.CarteFidelite = client.CarteFidelite
          this.Assure = client.Assure
          this.DCD = client.DCD
          /* this.ChefFamille = client.ChefFamille */
          this.PasSMS = client.PasSMS
          this.PasMail = client.PasMail

          this.$vs.loading.close()


          if (this.$store.state.client.clients.length === 0) {
            this.getClientsOfficine(this.IdOfficine)
          }

        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getPays () {
      this.$store.dispatch('pays/getPays')
        .then(() => { })
        .catch((err) => { console.log(err) })
    },
    getVilles () {
      this.$store.dispatch('pays/getVilles')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    },
    getQuartiers () {
      this.$store.dispatch('pays/getQuartiers')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    },
    getClientsOfficine (id) {
      this.$store.dispatch('client/getClientsOfficine', id)
        .then(() => {
        })
        .catch((error) => { console.log(error) })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.IdOfficine = this.activeUserInfos.IdOfficine
      this.IdUser = JSON.parse(localStorage.getItem('userInfo'))._id
    }
  },
  created () {
    this.getClientById()
    if (this.$store.state.pays.pays.length === 0) this.getPays()
    if (this.$store.state.pays.villes.length === 0) this.getVilles()
    if (this.$store.state.pays.quartiers.length === 0) this.getQuartiers()

  }

}
</script>
